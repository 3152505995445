import React from "react"
import Player from "../../blocks/player"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  player: playersYaml(slug: { eq: "sorokin" }) {
    ...PlayerInfo
  }
  stats: allStatsYaml {
    nodes {
      key
      value_ru
      average
    }
  }
}`

export default class Sorokin extends Player {
  renderDescription() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Универсальный крайний защитник, тактически выверенный, умеющий страховать партнеров и пользоваться сильным корпусом.</p>
          <p>Может сыграть слева и справа, владеет обеими ногами. Адаптируется как к роли линейного игрока (3-5-2), так и просто крайнего защитника.</p>
          <p>Жестко действует в подкате, умеет навязывать единоборства соперникам в сложных ситуациях.</p>
          <p>На хорошем уровне ведет верховые единоборства. Далеко и точно вводит мяч из-за боковой.</p>
          <p>Выступает за молодежную сборную Латвии U21.</p>
        </div>
      </div>
    )
  }
}